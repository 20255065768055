.error-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  .main-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Gilroy-ExtraBold";
    font-size: 24px;
    color: #333;
    flex-grow: 1;
  }
  .buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin-top: 26px;
  }
}
