.error-boundary-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  & > h1 {
    text-align: center;
    margin: 0 24px;
  }
}
