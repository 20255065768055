.popup-container {
  width: 720px;
  min-height: 328px;
  background-color: white;
  padding: 44px;
  opacity: 1;
  position: relative;
  display: flex;
  & > img {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }
}
