@mixin dynamicHover($hoverColor) {
  &:hover {
    background-color: $hoverColor;
  }
}
@mixin primaryColor($primaryColor) {
  color: $primaryColor;
}

.custom-button {
  width: 200px;
  height: 44px;
  border-radius: 5px;
  border: none;
  outline: none;
  margin: 7px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  color: white;
  @include dynamicHover(var(--hoverColor));
}

.transparent {
  background-color: transparent;
  font-weight: bolder;
  border: 3px solid var(--mainColor);
  color: var(--mainColor);
}

.solid {
  color: white;
  background-color: var(--mainColor);
}

.disabled {
  background-color: var(--hoverColor);
  color: white;
  cursor: not-allowed;
  opacity: 0.5;
}

.cancel {
  background-color: transparent;
  color: #a7a7a7;
}

.custom-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
