.avatar-wrapper {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  background-color: #9696a0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  & > span {
    font-family: "Gilroy-ExtraBold";
    color: white;
  }
}
