.error-row-container {
  & > div {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    & > span {
      margin-right: 8px;
    }
  }
}
