.custom-button-wrapper {
  .custom-button {
    width: 200px;
    height: 44px;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
  }

  .transparent {
    background-color: transparent;
    border: 2px solid white;
    color: white;
  }

  .solid {
    color: white;
  }

  .cancel {
    background-color: transparent;
    color: #a7a7a7;
  }

  .custom-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
